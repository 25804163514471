/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import '../common-styles/404.scss';
import NotFoundImage from '../images/misc/404.svg';

const NotFoundPage = () => (
  <Layout isBlog={true}>
    <div className="main notfoundpage">
      <section className="container">
        <div className="text-container">
          <h2>Oops</h2>
          <p>
            Unfortunately, the page you&apos;ve requested cannot be displayed. Please
            return to the last page or the home page to find what you&apos;re looking
            for.
          </p>
          <p className="my-2">Sorry for the inconvenience caused.</p>
          <div>
            <Link to="/" className="btn btnklub3">Return Home</Link>
          </div>
        </div>
        <div className="image-container">
          <h2>Oops</h2>
          <img src={NotFoundImage} alt="404" />
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
