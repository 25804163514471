const CURRENT_ENV = process.env.GATSBY_ACTIVE_ENV || 'production';

// constants
let PLATFORM_URL = 'https://app.klubworks.com/';
let BRANDS_APP_URL = 'https://brands.klubworks.com/';
export const CAREER_PAGE = 'https://klubworks.springrecruit.com/careers';

if (CURRENT_ENV === 'develop') {
  PLATFORM_URL = 'https://dev-app.klubworks.com/';
  BRANDS_APP_URL = 'https://dev-brands.klubworks.com/';
} else if (CURRENT_ENV === 'staging') {
  PLATFORM_URL = 'https://staging-app.klubworks.com/';
  BRANDS_APP_URL = 'https://staging-brands.klubworks.com/';
}

export const openURL = (page, customUTMSource = '') => {
  switch (page) {
    case 'patron-app':
      window.open(`${PLATFORM_URL}${getUtmParamsFromSession()}`, '_blank');
      break;
    case 'patron-app_signup':
      saveCustomURLParamToSession('?utm_source=blog&utm_medium=footer_cta');
      window.open(`${PLATFORM_URL}signup${getUtmParamsFromSession()}`, '_blank');
      break;
    case 'patron-app_login':
      saveCustomURLParamToSession('?utm_source=blog&utm_medium=footer_cta');
      window.open(`${PLATFORM_URL}${getUtmParamsFromSession()}`, '_blank');
      break;
    case 'brand_app_signup':
      saveCustomURLParamToSession(customUTMSource || '?utm_source=blog&utm_medium=footer_cta');
      window.open(
        `${BRANDS_APP_URL}signup${getUtmParamsFromSession()}`,
        '_blank'
      );
      break;
    case 'brand_app_calc':
      window.open(
        `${BRANDS_APP_URL}calculator${getUtmParamsFromSession()}`,
        '_blank'
      );
      break;
    case 'brand_app':
      window.open(`${BRANDS_APP_URL}${getUtmParamsFromSession()}`, '_blank');
      break;
    default:
      break;
  }
};

export const saveURLParamToSession = () => {
  if (typeof window !== 'undefined') {
    const { search } = new URL(window.location);
    if (search) {
      localStorage.setItem('utmParams', search);
    }
  }
};

export const saveCustomURLParamToSession = (param) => {
  if (param) {
    localStorage.setItem('utmParams', param);
  }
};

export const getUtmParamsFromSession = () => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem('utmParams') || '';
  }
  return '';
};
